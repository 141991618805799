import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const Barcode = ({ onChange }) => {
  return (
    <BarcodeScannerComponent
      width={"100%"}
      onUpdate={(err, result) => {
        if (result) {
          onChange(result.text);
        }
      }}
      facingMode='environment'
    />
  );
};

export default Barcode;
