import React from "react";
import "./App.css";
import { Flex } from "antd-mobile";
import Logo from "./Assets/logo.svg";

const ErrorComponent = () => {
    return (
        <div>
            <div className="flex-container">
                <Flex wrap="wrap">
                    <Flex.Item>
                        <div className="brand-logo">
                            <a href="/">
                                <img src={Logo} alt="Logo" width="150" />
                            </a>
                        </div>
                        <div className="white-card">
                            <p style={{textAlign: 'center'}}>Aradığınız sayfa bulunamadı</p>
                        </div>
                    </Flex.Item>
                </Flex>
            </div>
        </div>
    );
};

export default ErrorComponent;
