import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Read from "./Read";
import Result from "./Result";
import NoMatch from "./Error";
const RootApp = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/read">
          <Read />
        </Route>
        <Route exact path="/notfound">
          <NoMatch />
        </Route>
        <Route exact path="/:sampleId">
          <Result />
        </Route>
        <Route exact path="/">
          <Redirect to="/read" />
        </Route>

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
};
export default RootApp;
