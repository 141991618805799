import React from "react";
import QRCode from "react-qr-code";

const QrCodeComponent = ({ value }) => {
  return (
    <QRCode
      value={value}
      bgColor="#FFFFFF"
      fgColor="#000000"
      level="L"
      size={200}
    />
  );
};

export default QrCodeComponent;
