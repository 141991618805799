import Axios from "axios";
const API_URL = {
  prod:"https://apilims.intertekturkey.com/lims-public/qrcode/",
  test:"https://apilims-test.intertekturkey.com/lims-public/qrcode/"
}
const BASE_API = Axios.create({
  baseURL: API_URL.prod,
});
BASE_API.defaults.headers.common["X-API-KEY"] =
  "e3784eb2-b842-4a31-a204-14bc90bc5856";

export const readSample = (sampleId) => {
  return BASE_API.get(`/${sampleId}`);
};
