import React, { useState, useCallback, useEffect } from "react";
import "./App.css";
import { Flex, Result, Icon, ActivityIndicator, Switch } from "antd-mobile";
import { useHistory, useParams } from "react-router-dom";
import Logo from "./Assets/logo.svg";
import { readSample } from "./Service";
import QrCodeComponent from "./QrCode";
const ResultPage = () => {
    const history = useHistory();
    const { sampleId } = useParams();
    const [language, setLanguage] = useState(true); //  true ise tr-TR  değilse en-EN
    const [sampleData, setSampleData] = useState({
        status: null,
        reply: null,
        message: null,
    });

    useEffect(() => {
        const getData = () => {
            console.log("getData", sampleId);
            readSample(sampleId)
                .then((result) => {
                    console.log("readSample-result", result.data);
                    setSampleData(result.data);
                })
                .catch((error) => {
                    console.log("readSample-error", error);
                    history.push("/notfound");
                });
        };
        if (!!sampleId) {
            getData();
        } else {
            history.push("/notfound");
        }
    }, [sampleId, history]);

    const renderContent = useCallback(() => {
        const { status, reply, message } = sampleData;
        if (status == null) {
            return (
                <div style={{textAlign: 'center'}}>
                    <ActivityIndicator text="Please wait." />
                </div>
            );
        }
        if (!status) {
            return (
                <Result
                    img={
                        <Icon
                            type="cross-circle-o"
                            className="spe"
                            style={{ fill: "#F13642" }}
                        />
                    }
                    title="İşlem Sonucu"
                    message={message}
                />
            );
        } else {
            const resulttext = language
                ? reply?.statusName
                : reply?.statusNameEn;
            const resulttitle = language ? "Rapor No:" : "Report Number:";
            const resultLabName =language ? reply?.labName : reply?.labNameEn
            return (
                <>
                    <div
                        className="result-qr-section"
                        style={{ textAlign: "center" }}
                    >
                        <QrCodeComponent value={sampleId} />
                    </div>
                    <Result
                        img={resultIcon(
                            "/tick.png"
                        )}
                        title={`${resulttitle} ${reply?.reportName || ""}`}
                        message={resulttext || ""}
                        
                    />
               
                    <p
                        style={{
                            fontSize: "14px",
                            textAlign: "center",
                        }}
                    >
                        {language
                            ? `Bu rapor Its Caleb Brett Deniz Survey A.Ş. tarafından ${reply?.labName}nda oluşturuluştur.`
                            : `  This report has been prepared by Its Caleb Brett Marine Survey A.Ş. It was created by ${reply?.labNameEn}.`}
                           
                    </p>
                </>
            );
        }
    }, [sampleData, sampleId, language]);
    const resultIcon = (src) => (
        <img src={src} className="spe am-icon am-icon-md" alt="" />
    );

    return (
        <div className="flex-container">
            <Flex wrap="wrap">
                <Flex.Item>
                    <div className="brand-logo">
                        <a href="/">
                            <img src={Logo} alt="Logo" width="150" />
                        </a>
                    </div>

                    <div className="white-card">
                        <div
                            className="lang-select"
                            style={{ position: "absolute", top: -30, right: 15 }}
                        >
                            <span
                                className="lang-text"
                                style={{
                                    display: "block",
                                    fontSize: "13px",
                                    textAlign: "center",
                                }}
                            >
                                {language ? "Türkçe" : "English"}
                            </span>
                            <Switch
                                checked={language}
                                onChange={(value) => setLanguage(value)}
                            />
                        </div>
                        <div className="result">
                            <div className="result-sub-title">
                                {language ? "Numune Durumu" : "Sample Status"}
                            </div>
                            <div className="result-content">
                                {renderContent()}
                            </div>
                        </div>
                    </div>
                </Flex.Item>
            </Flex>
        </div>
    );
};

export default ResultPage;
