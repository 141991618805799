import React, { useState, useCallback, useEffect } from "react";
import "./App.css";
import { Flex } from "antd-mobile";
import { useHistory } from "react-router-dom";
import Barcode from "./Barcode";
import Logo from "./Assets/logo.svg";
const ReadPage = () => {
  const history = useHistory();
  const [data, setData] = useState(null);
  const onChange = useCallback((value) => setData(value), []);
  useEffect(() => {
    if (!!data) history.push(`/${data}`);
  }, [data,history]);
  return (
    <div className="flex-container">
      <Flex wrap="wrap">
        <Flex.Item>
          <div className="brand-logo">
            <a href="/">
              <img src={Logo} alt="Logo" width="150" />
            </a>
          </div>

          <div className="white-card">
            {!data && (
              <>
                <div className="result-sub-title">Qr Kodu Okutun</div>
                <Barcode onChange={onChange} />
              </>
            )}
          </div>
        </Flex.Item>
      </Flex>
    </div>
  );
};

export default ReadPage;
